import logo from './logo.svg';
import './App.css';
import Artie from './components/artie';
import Istory from './components/istory';
import Tom from './assets/babytom.jpg';

function App() {
  return (
    <div>
      {/* <Artie /> */}
      <Istory />
    </div>
  );
}

export default App;
