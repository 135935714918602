import TomJerry from '../assets/tom_jerry.jpg';
import TomJerry2 from '../assets/tomJerry2.jpg';
import LionCub from '../assets/lioncub.jpg';
import BabyTom from '../assets/babytom.jpg';

export const config={
    speech_key : "318269a1df7d4d8a8019734acb625594",
    speech_region : "eastus"
}

export const testData = [
    {
        "id": 1,
        "img": TomJerry,
        "text": "Are you ready for the demo?",
        "response": "Yes.",
        "interactive": true,
        "voicename": "en-GB-SoniaNeural"
    },
    {
        "id": 2,
        "img": TomJerry2,
        "text": "This scene is non interactive and it will move to next scene automatically.",
        "response": "",
        "interactive": false,
        "voicename": "en-GB-LibbyNeural"
        
    },
    {
        "id": 3,
        "img": LionCub,
        "text": "An Interactive scene and it will enable the microphone only when the dialogue is over and automatically turn off once the correct response is given",
        "response": "Nice.",
        "interactive": true,
        "voicename": "en-US-GuyNeural"
    },
    {
        "id": 4,
        "img": BabyTom,
        "text": "Demo is over. How is the experience so far. Enjoyed? Please say only yes.",
        "response": "Yes.",
        "interactive": true,
        "voicename": "en-US-SaraNeural"
    },
]