import React, { useState, useEffect,useLayoutEffect, useRef, createRef } from 'react';
import {config, testData} from "./utils";
import axios from "axios";
import { ResultReason, SpeechConfig, AudioConfig, SpeechRecognizer, CancellationReason, SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk';
import TomJerry from "../assets/tom_jerry.jpg";
import TomJerry2 from "../assets/tomJerry2.jpg";
import BabyTom from "../assets/babytom.jpg";
import LionCub from "../assets/lioncub.jpg";
import "./artie.css";

function Artie() {
    // console.log("helllo",testData);
    const [displayText, setDisplayText] = useState(false);
    const [authToken, setAuthToken] = useState(false);

    // states for playing story
    const [dialogue, setDialogue] = useState("");
    const [currentScene, setCurrentScene] = useState("");
    const [nextScene, setNextScene] = useState("");
    const [response, setResponse] = useState("");
    const [interactive, setInteractive] = useState(false);
    const [sceneChange, setSceneChange] = useState(false);
    const [createStory, setCreateStory] = useState({});
    const [initialRender, setInitialRender] = useState(false);
    const[imageSource, setImageSource] = useState(null);
    const myRefs = useRef([]);

    //currentscene variable

    // use effect to mount the data initial render
    useEffect(() => {
        myRefs.current = testData.map((element, i) => myRefs.current[i] ?? createRef());
        // console.log("myRefs",myRefs)
        // console.log("load data only once")
        //setting up current_scene_data
        setCreateStory(testData[0]);
        setImageSource(testData[0].img);
        setCurrentScene(0)
    }, []);

    useEffect(() => {
        //do operation on state change
        console.log("triggers when the state change",initialRender)
        if(createStory && initialRender){
            console.log("effect of india",createStory)
            playiStory();
        }
        
     },[createStory])

    //  useLayoutEffect(() => {
    //     console.log("componentDidUpdateFunction");
    //   },[dialogue == 'hellos']);

    function handleSubmit(e) {
        // e.preventDefault();
        const headers = { 
            headers: {
                'Ocp-Apim-Subscription-Key': config.speech_key,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        axios.post(`https://${config.speech_region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, null, headers)
        .then(function (response) {
            console.log("tokenresponse",response)
            setAuthToken(response.data)
            
        })
        .catch(function (error) {
            console.log("error",error)
        });
    
        // try {
        //     const tokenResponse = axios.post(`https://${config.speech_region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, null, headers);
        //     console.log("tokenresponse",tokenResponse)
        //     setAuthToken(tokenResponse)
        // } catch (err) {
        //     console.log("error",err)
        // }
    }

    function sttFromMic() {
        console.log("inside mircrophone function",authToken);
        // const tokenObj = getTokenOrRefresh();
        const speechConfig = SpeechConfig.fromAuthorizationToken(authToken, "eastus");
        speechConfig.speechRecognitionLanguage = 'en-US';
        
        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechRecognizer(speechConfig, audioConfig);

        setDisplayText("speak into your microphone...")

        recognizer.startContinuousRecognitionAsync();

        recognizer.recognizing = (s, e) => {
            console.log(`RECOGNIZING: Text=${e.result.text}`);
        };
        
        recognizer.recognized = (s, e) => {
            if (e.result.reason == ResultReason.RecognizedSpeech) {
                console.log(`RECOGNIZED: Text=${e.result.text}`);
                setDisplayText(e.result.text)
                if(e.result.text == 'Yes.' || e.result.text == 'Definitely.'){
                    console.log("hello yes of india")
                    recognizer.stopContinuousRecognitionAsync();
                }
            }
            else if (e.result.reason == ResultReason.NoMatch) {
                setDisplayText(false)
                console.log("NOMATCH: Speech could not be recognized.");
            }
        };
        
        recognizer.canceled = (s, e) => {
            console.log(`CANCELED: Reason=${e.reason}`);
        
            if (e.reason == CancellationReason.Error) {
                console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                console.log("CANCELED: Did you set the speech resource key and region values?");
            }
        
            recognizer.stopContinuousRecognitionAsync();
        };
        
        recognizer.sessionStopped = (s, e) => {
            console.log("\n    Session stopped event.");
            recognizer.stopContinuousRecognitionAsync();
        }

        // recognizer.recognizeOnceAsync(result => {
        //     let displayText;
        //     console.log("result of microphone",result)
        //     if (result.reason === ResultReason.RecognizedSpeech) {
        //         displayText = `RECOGNIZED: Text=${result.text}`
        //     } else {
        //         displayText = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
        //     }
        //     setDisplayText(displayText)
        //     console.log("display text finaled",displayText)

        // });
    }

    function playiStory(){
        console.log("inside play istory function",createStory);
        if(createStory.text){
            console.log("dialogue is available");
            playText(createStory)
        }

    }

    function playText(data){
        console.log("data in play text",data);
        const textConfig = SpeechConfig.fromSubscription("692d6b3723d74d44a3a44ec64672a535", "eastus");
        textConfig.speechSynthesisOutputFormat = 5;
        textConfig.speechSynthesisLanguage = "en-US";

        textConfig.SpeechSynthesisOutputFormat = "Riff16Khz16BitMonoPcm";
        textConfig.speechSynthesisVoiceName = data.voicename;
        
        const textSynthesizer = new SpeechSynthesizer(textConfig);

        const dialogueText = data.text
        const interactive = data.interactive
        if(dialogueText){
            textSynthesizer.speakTextAsync(
                dialogueText,
                function (result) {
                    if (result.reason === ResultReason.SynthesizingAudioCompleted) {
                        console.log("synthesis finished.");
                      } else {
                        console.error("Speech synthesis canceled, " + result.errorDetails +
                            "\nDid you set the speech resource key and region values?");
                      }
                      console.log("closing synthesiser")
                      textSynthesizer.close();
                },
                function (err) {
                    console.log(err);
                    textSynthesizer.close();
                }
            );
    
            textSynthesizer.synthesisCompleted = function (s, e) {
                console.log("durations",e.result.audioDuration);
                const textDuration = e.result.audioDuration/10000;
                console.log("text duration",textDuration) 
                if(interactive){
                    setTimeout(() => {
                        console.log("timeout function")
                        micOnApi(data);
                    }, textDuration);

                }
                else{
                    setTimeout(() => {
                        const scene_change = currentScene + 1
                        setCurrentScene(scene_change)
                        setInitialRender(true);
                        setCreateStory(testData[scene_change]);
                        setImageSource(testData[scene_change].img);
                    }, textDuration);
                    //need to logics here also
                }
                // var str = `SynthesisCompleted event: \
                //             \r\n\tAudioData: ${e.result.audioData.byteLength} bytes \
                //             \r\n\tAudioDuration: ${e.result.audioDuration}`;
                // console.log(str);
            };
        }
        else{
            //logics needs to be written later
        }
        

    }

    function micOnApi(data){
        console.log("inside microphone on function");
        const headers = { 
            headers: {
                'Ocp-Apim-Subscription-Key': config.speech_key,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        axios.post(`https://${config.speech_region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, null, headers)
        .then(function (response) {
            console.log("tokenresponse",response)
            setAuthToken(response.data)
            micOn(data,response.data,true)
            
        })
        .catch(function (error) {
            console.log("error",error)
        });
    }

    function micOn(data,authToken, micBoolean){
        const speechConfig = SpeechConfig.fromAuthorizationToken(authToken, "eastus");
        speechConfig.speechRecognitionLanguage = 'en-US';
        
        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechRecognizer(speechConfig, audioConfig);

        setDisplayText("speak into your microphone...")

        recognizer.startContinuousRecognitionAsync();

        recognizer.recognizing = (s, e) => {
            // console.log(`RECOGNIZING: Text=${e.result.text}`);
        };
        
        recognizer.recognized = (s, e) => {
            if (e.result.reason == ResultReason.RecognizedSpeech) {
                console.log(`RECOGNIZED: Text=${e.result.text}`);
                setDisplayText(e.result.text)
                if(e.result.text == data.response || e.result.text == 'Definitely.'){
                    console.log("hello yes of india");
                    recognizer.stopContinuousRecognitionAsync();
                    const scene_change = currentScene + 1
                    setCurrentScene(scene_change)
                    setInitialRender(true);
                    setCreateStory(testData[scene_change]);
                    setImageSource(testData[scene_change].img);

                }
            }
            else if (e.result.reason == ResultReason.NoMatch) {
                setDisplayText(false)
                console.log("NOMATCH: Speech could not be recognized.");
            }
        };
        
        recognizer.canceled = (s, e) => {
            console.log(`CANCELED: Reason=${e.reason}`);
        
            if (e.reason == CancellationReason.Error) {
                console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                console.log("CANCELED: Did you set the speech resource key and region values?");
            }
        
            recognizer.stopContinuousRecognitionAsync();
        };
        
        recognizer.sessionStopped = (s, e) => {
            console.log("\n    Session stopped event.");
            recognizer.stopContinuousRecognitionAsync();
        }
        if(micBoolean){
            recognizer.stopContinuousRecognitionAsync();
        }
    }

    function textToSpeech(){
        console.log("inside text to speech")
        const speechConfig1 = SpeechConfig.fromSubscription("692d6b3723d74d44a3a44ec64672a535", "eastus");
        speechConfig1.speechSynthesisOutputFormat = 5; // mp3
        speechConfig1.speechSynthesisLanguage = "en-US";

        const text = "Tesnions of india.Welcome to artie hacking world. When you have a dream to chase nothing can stop you"

        speechConfig1.SpeechSynthesisOutputFormat = "Riff16Khz16BitMonoPcm";
        speechConfig1.speechSynthesisVoiceName = "en-GB-LibbyNeural";
        // The voice setting will overwrite language setting.
        // The voice setting will not overwrite the voice element in input SSML.
        const synthesizer = new SpeechSynthesizer(speechConfig1);

        synthesizer.speakTextAsync(
            text,
            function (result) {
                if (result.reason === ResultReason.SynthesizingAudioCompleted) {
                    console.log("synthesis finished.");
                  } else {
                    console.error("Speech synthesis canceled, " + result.errorDetails +
                        "\nDid you set the speech resource key and region values?");
                  }
                  console.log("closing synthesiser")
                synthesizer.close();
            },
            function (err) {
                console.log(err);
                synthesizer.close();
            }
        );
        console.log("lets go babe");
        synthesizer.synthesisCompleted = function (s, e) {
            console.log("durations",e.result.audioDuration)
            var str = `SynthesisCompleted event: \
                        \r\n\tAudioData: ${e.result.audioData.byteLength} bytes \
                        \r\n\tAudioDuration: ${e.result.audioDuration}`;
            console.log(str);
        };
        
    }
    
    return (
        <>
        {/* <h1>Welcome to artie world</h1>
        <button onClick={handleSubmit}>click me</button>
        <button onClick={sttFromMic}>open microphone</button>
        <div className='imgContainer'>
            <img style={{width:"500px"}} src={TomJerry} alt="tom and jerry image" />
            <img style={{width:"500px"}} src={TomJerry2} alt="tom and jerry image" />
            <img style={{width:"500px"}} src={LionCub} alt="tom and jerry image" />
            <img style={{width:"500px"}} src={BabyTom} alt="tom and jerry image" />
        </div> */}
        
        <div className='imgContainer'>
            {
                // testData.map(function(item, i){
                //     // console.log('test',item.img);
                //     return <div ref={myRefs.current[i]} key={item.id} style={item.id == 1 ? {} : {position:'absolute',visibility:'hidden'}}>
                //                 <img key={item.id} className='image' src={item.img}  />
                //                 {/* <button>tesing the event action</button> */}
                //             </div>
                // })
            }
        </div>
        <div>
            <img className='image' alt="artie image" src={imageSource}  />
        </div>
        <div className='bottomBar'>
            <button onClick={textToSpeech}>play</button>
            <button onClick={playiStory}>play iStory</button>
            <button>microphone on</button>
            <button>microphone off</button>
            <button>pause</button>
        </div>
        
        {displayText ? <div><h6>{displayText}</h6></div> : null}
        </>
    );
}
export default Artie;