import React, { useState, useEffect, useRef } from 'react';

import { config } from "./utils";
import axios from "axios";
import { ResultReason, SpeechConfig, AudioConfig, SpeechRecognizer, CancellationReason, SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk';
import jwtDecode from 'jwt-decode';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import Mic from '../assets/mic.png';
import Play from '../assets/Play.png';
import playCircle from '../assets/playCircle.png';
import pause_disabled from '../assets/pause_disabled.png';
import Volume from '../assets/Volume.png';
import Previous from '../assets/Previous.png';
import Next from '../assets/Next.png';
import Caption from '../assets/Caption.png';
import Replay from '../assets/Replay.png';
import FullScreen from '../assets/FullScreen.png';
import './istory.css';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import demo_video from "../assets/bike.mp4";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import watermark from "../assets/Watermrk 2-0.png"
import ReplayIcon from '@mui/icons-material/Replay';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#212529',
    "&:focus": {
        outline: 'none'
    },
    boxShadow: 24,
    p: 2,
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
};
// ReactGA.initialize("G-6XQYW6XWT1");
function Istory() {
    const queryParameters = new URLSearchParams(window.location.search)
    let id = queryParameters.get("iStory_id")
    let from_params = queryParameters.get("from")
    const [allScenes, setAllScenes] = useState([])
    const [currentScene, setCurrentScene] = useState({});
    const [sceneData, setSceneData] = useState(null);
    const [sceneDialogue, setSceneDialogue] = useState([]);
    const [storyname, setStoryName] = useState("");
    const [initialRender, setInitialRender] = useState(false);
    const [scenePlay, setScenePlay] = useState("");
    const [imageSource, setImageSource] = useState(null);
    const [videoSource, setVideoSource] = useState(null);
    const [audioSource, setAudioSource] = useState(null)
    const [authToken, setAuthToken] = useState(false);
    const [displayText, setDisplayText] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [play_btn, setPlay_btn] = useState(false);
    const [freeze, setFreeze] = useState(false)
    const [dialogueIndex, setDialogueIndex] = useState(null)
    const [micOnState, setMicOnState] = useState(null)
    const [micRecogniser, setMicRecogniser] = useState()
    const [nextScene, setNextScene] = useState(true)
    const [nondialogue, setNonDialogue] = useState(false)
    const [sceneType,setSceneType] =useState("Noninteraction")
    const [interactionSuccess, setInteractionSuccess] = useState(false)
    const [initialInteraction, setInitialInteraction] = useState(true)
    const [interactionSceneChange, setInteractionSceneChange] = useState(null)
    const [updateState,setUpdateState]=useState(false)
    const [mute,setMute]=useState(false)
    const [storyStatus,setStoryStatus]=useState("PUBLISHED")
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [email,setEmail] = useState("");
    const [emailOpen, setEmailOpen] = useState(false);
    const handleEmailOpen = () => setEmailOpen(true);
    const handleEmailClose = () => setEmailOpen(false);
    const [previous,setPrevious]=useState(null)
    const [caption,setCaption]=useState(false)
    const [captionText,setCaptionText]=useState(false)
    const [pastScene,setPastScene]=useState()
    const [nondialogue_pause,setNonDialogue_pause]=useState()
    let myInterval
    let timeoutId;
    let textSynthesizer;
    const [img_load,setImg_load] = useState(false)
    const [video_load,setvideo_load] = useState(false)
    const image_ref = useRef(null)
    const [slides_position,setSlides_position] = useState(null)

    const [browser_back_btn,setBrowser_back_btn] = useState(null)
    const [refresh_btn, setRefresh_btn] = useState(null)

    const [iStory_startTime, setIstory_startTime] = useState(0)
    // audio_gtv --> audio greater than video
    const [audio_gtv,setAudio_gtv] = useState(null)
    const [isIstoryEnded,setIsIstoryEnded] = useState(false)

    const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const [analyticssocket,setAnalyticsSocket] = useState('');
    
    const [isTabSwitched,setIsTabSwitched] = useState(false)

    // const base_url = "http://127.0.0.1:8001"
    // const domain_name = "127.0.0.1:8001"
    const base_url = "https://istorydesigncenter.com"
    const domain_name = "istorydesigncenter.com:8001"
    useEffect(() => {
        if (id==144){
            id=187
        }
        const handlePopState = () => {
        // analyticssocket.send(10)
            setBrowser_back_btn(true);
        };
        
        const handleBeforeUnload = () => {
        // analyticssocket.send(35)
            setRefresh_btn(true);
        };
        
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                
                setIsTabSwitched(true)
                // analyticssocket.send(40)
            } else {
                
                setIsTabSwitched(false)
                // analyticssocket.send(50)
            }
        };
        
        window.addEventListener('popstate', handlePopState);
        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        
        axios.get(`${base_url}/istory/${id}/`)
            .then(function (response) {
                const data = response.data;
                setAllScenes(data)
                setStoryName(data.name);
                setCurrentScene(0)
                setSceneData(data.scene_list[0])
                setSceneDialogue(data.scene_list[0].scene_dialogue)
                if(data.scene_list[0].scene_play == "VIDEO") {
                    setScenePlay("VIDEO");
                    setVideoSource(data.scene_list[0].scene_video);
                }
                else{
                    setScenePlay("IMAGE")
                    setImageSource(data.scene_list[0].scene_img)
                }
                
                setSceneType(data.scene_list[0].scenetype)
        
                setStoryStatus(response.data.status)

                if(id && data.status === "PUBLISHED" && from_params === null){
                    axios.patch(`${base_url}/istory/${id}/`,{
                        "story_pv":data.story_pv == null?1:data.story_pv + 1
                    })
                }
                // socket = new WebSocket(`${wsProtocol}//127.0.0.1:8000/ws/analytics`)
                // let socket =  new WebSocket(`${wsProtocol}//uat.istorydesigncenter.com:8001/ws/analytics`)
                // console.log(socket)
                // setAnalyticsSocket(socket)
                setAnalyticsSocket(new WebSocket(`${wsProtocol}//${domain_name}/ws/analytics`))
                // socket.onopen = ()=>{
                //     console.log("connected to ws/analytics")
                // }
            })
            .catch(function (error) {
                console.log("error", error)
            });
            const storedToken = sessionStorage.getItem('speech_token');
            if (storedToken) {
              setAuthToken(storedToken);
              return;
            }
            // Fetch a new speech token from the Django API
            sttToken()
            // axios.get('/api/get_speech_token/')
            //   .then(response => {
            //     const token = response.data.token;
            //     setToken(token);
            //     // Store the speech token in sessionStorage
            //     sessionStorage.setItem('speech_token', token);
            //   })
            //   .catch(error => {
            //     console.error(error);
            //   });
        
        return()=>{
            // analyticssocket.close();
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }  

    }, []);


    const handleEmailSkip = (e) => {
        e.preventDefault();
        let scene_change = currentScene + 1
        setPastScene(currentScene)
        setCurrentScene(scene_change)
        setInitialRender(true);
        setSceneType(allScenes.scene_list[scene_change].scenetype)
        setSceneData(allScenes.scene_list[scene_change]);
        setPlay_btn(false)
        if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
            setScenePlay("VIDEO");
            setVideoSource(allScenes.scene_list[scene_change].scene_video);
            // setAudioSource(allScenes.scene_list[scene_change].scene_video)
        }
        else {
            setScenePlay("IMAGE");
            setImageSource(allScenes.scene_list[scene_change].scene_img);
        }
        handleEmailClose()
    }

    const handleEmailSubmit = (e) => {
        e.preventDefault();

        const email_data = sceneData.email_interaction_scene[0];
        let attachment_files = [];
        let file_names = [];
        
        if(email_data){
            if(email_data.attachment_files){
                attachment_files = JSON.parse(email_data.attachment_files)
                file_names = JSON.parse(email_data.file_names)
            }
            axios.post(`${base_url}/istory/send-email/`,{
                "recipients": [email],
                "cc":[email_data.email_cc],
                "bcc":[email_data.email_bcc],
                "subject": email_data.email_subject,
                "message": email_data.email_body,
                "attachments": attachment_files,
                "file_names": file_names
            })
            .then(function (response) {
                if(response.status === 200) {
                    let scene_change = currentScene + 1
                    setPastScene(currentScene)
                    setCurrentScene(scene_change)
                    setInitialRender(true);
                    setSceneType(allScenes.scene_list[scene_change].scenetype)
                    setSceneData(allScenes.scene_list[scene_change]);
                    setPlay_btn(false)
                    if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
                        setScenePlay("VIDEO");
                        setVideoSource(allScenes.scene_list[scene_change].scene_video);
                        // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                    }
                    else {
                        setScenePlay("IMAGE");
                        setImageSource(allScenes.scene_list[scene_change].scene_img);
                    }
                    handleEmailClose()
                }
            })
            .catch(function (error) {
                console.log("error",error)
            });
            
        }
    };

    let Avg_timeCalculation = ()=>{
        try{
            // console.log("-----updating avg time-----")
            let endTime
            if (iStory_startTime < new Date().getSeconds()){
                endTime = new Date(). getSeconds()
                // console.log("times before patch if from tab switch -----",allScenes.story_ct,"\n",iStory_startTime,'\n',new Date().getSeconds(),"\n",allScenes.story_ct == null? Math.round(new Date().getSeconds() - iStory_startTime): Math.round(( allScenes.story_ct + (new Date().getSeconds() - iStory_startTime) )/2) )
            }
            else if (iStory_startTime > new Date().getSeconds()){
                endTime = iStory_startTime + (60 - iStory_startTime) + new Date().getSeconds()
                // console.log("times before patch else from tab switch-----",allScenes.story_ct,"\n",iStory_startTime,'\n',endTime,"\n",allScenes.story_ct == null? Math.round(endTime - iStory_startTime): Math.round(( allScenes.story_ct + (endTime - iStory_startTime) )/2) )
            }
            axios.patch(`${base_url}/istory/${id}/`,{
                //ct -- Completed Time average
                "story_ct":allScenes.story_ct == null? Math.round(endTime - iStory_startTime) : Math.round(( allScenes.story_ct + (endTime - iStory_startTime) )/2)
            })
        }
        catch(err){
            console.log("error while updating avg time---",err)
        }
    }
    ///////// Tab switched logic for avg time analytics ///////
    useEffect(()=>{
        // console.log("-----------------tab useeffect----------",play_btn,iStory_startTime,isTabSwitched)
        if(play_btn == true && iStory_startTime > 0 && isTabSwitched){
            // console.log("this should trigger when istory playing and switched the tab")
            Avg_timeCalculation()
        }
        else if(!isTabSwitched && iStory_startTime > 0){
            // console.log("started new session")
            setIstory_startTime(new Date().getSeconds())
        }
    },[isTabSwitched])


    useEffect(() => {
        //do operation on state change
        if (sceneData && initialRender) {
            playiStory();
        }
       
    }, [sceneData,updateState,])
    // control bar & story name aligment
    useEffect(()=>{
        if(imageSource != null && image_ref.current?.getBoundingClientRect().width > 0){
            setSlides_position(image_ref.current.getBoundingClientRect())
        }
        else if (videoSource != null && videoRef.current?.getBoundingClientRect().width > 0){
            setSlides_position(videoRef.current.getBoundingClientRect())
        }
        window.addEventListener('resize',()=>{ 
            if(imageSource != null && image_ref.current?.getBoundingClientRect().width > 0){
                setSlides_position(image_ref.current.getBoundingClientRect())
            }
            else if (videoSource != null && videoRef.current?.getBoundingClientRect().width > 0){
                setSlides_position(videoRef.current.getBoundingClientRect())
            }
        })
    },[img_load,video_load])

    // user engament time
    useEffect(()=>{
        if( play_btn == true && sceneData  && allScenes.scene_list?.[allScenes.scene_list?.length -1].id === sceneData.id && sceneData.navigation == null  && scenePlay== "IMAGE" ){
            setIsIstoryEnded(true)
            if(allScenes.status == "PUBLISHED" && from_params === null){

                try{
                    Avg_timeCalculation()
                    axios.patch(`${base_url}/istory/${id}/`,{
                        //cv--Completed view
                        //ct -- Completed Time average
                        "story_cv":allScenes.story_cv == null?1:allScenes.story_cv + 1,
                    })
                }
                catch(err){
                    console.log("error while updating avg time---",err)
                }

            }
                
        }
        else if(play_btn && sceneData && allScenes.scene_list?.[0].id === sceneData.id && iStory_startTime === 0){
            setIstory_startTime(new Date().getSeconds())
        }
        // // tab swtiched
        // window.addEventListener('visibilitychange',()=>{
        //     document.hidden?console.log("tab not active"):console.log("tab is active")
        // })
        // let ws = new WebSocket("https://uat.istorydesigncenter.com/istory/137")
        // console.log(ws)
        
    },[allScenes,sceneData,play_btn])

    useEffect(() => {
        if (authToken) {
            const intervalId = setInterval(() => {
              const decodedToken = jwtDecode(authToken);
              const expirationTime = decodedToken.exp * 1000;
              const currentTime = new Date().getTime();
              if (currentTime + 60000 >= expirationTime) {
                sttToken()
              }
            }, 5000);
        
            return () => clearInterval(intervalId);
        }
      }, [authToken]);

    function sttToken() {
        const headers = {
            headers: {
                'Ocp-Apim-Subscription-Key': config.speech_key,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        axios.post(`https://${config.speech_region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, null, headers)
            .then(function (response) {
                setAuthToken(response.data)
                sessionStorage.setItem('speech_token', response.data);
                // micOn(data, response.data, true)
            })
            .catch(function (error) {
                console.log("error",error)
            });
    }

    const handleMouseMove = () => {        
        setIsDisabled(false);
            clearTimeout(timeoutId);  
            timeoutId=null  
            if(timeoutId===null){              
                timeoutId = setTimeout(function () {
                    setIsDisabled(true);
                 }, 5000)
            }         
    };

    const handleEnded = () => {
        // below if condition is triggred for last scene of asset video.
        // console.log("coming in handle ended",sceneData,play_btn)
        if(sceneData){
            // console.log("1")
            if( play_btn == true  && allScenes.scene_list?.[allScenes.scene_list?.length -1].id === sceneData.id && allScenes.status == "PUBLISHED" && from_params === null &&  iStory_startTime > 0 ){
                // console.log("hey, ur in last scene")
                axios.patch(`${base_url}/istory/${id}/`,{
                    "story_cv":allScenes.story_cv == null?1:allScenes.story_cv + 1 
                })
                Avg_timeCalculation()
            }
            // console.log("2")
            // console.log("coming in video ended")
            videoRef.current.currentTime = videoRef.current.duration - 1
            // if (videoRef.current != null){
            //     videoRef.current.autoplay = false
            //     videoRef.current.load()
            // }
            // Your code to be executed when the video finishes playing
            // console.log("3")
            if (sceneData.scene_dialogue.length == 0 && sceneData.scenetype == "NONINTERACTION") {
                if(sceneData.external_redirect_url){
                    // const a = document.createElement('a');
                    // a.href = sceneData.external_redirect_url;
                    // a.target = '_blank';
                    // document.getElementById("root").append(a)
                    // a.click();
                    if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){

                        analyticssocket.send(JSON.stringify({"type":"from play istory when redirection link","sd":sceneData}))
                    }
                    window.location.href = sceneData.external_redirect_url
                    // window.open(sceneData.external_redirect_url, '_blank');
                }
                else{
                    // console.log("4")
                    if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){

                        analyticssocket.send(JSON.stringify({"type":"from video end","sd":sceneData}))
                    }
                    let scene_change = currentScene + 1
                        if (sceneData.navigation) {
                            const index = allScenes.scene_list.findIndex(value => value.id == sceneData.navigation);
                            scene_change = index
                            setPrevious(currentScene)
                        }
                        setPastScene(currentScene)
                        setPlay_btn(false)
                        setCurrentScene(scene_change)
                        setInitialRender(true);
                        setSceneData(allScenes.scene_list[scene_change]);
                        setSceneType(allScenes.scene_list[scene_change].scenetype)
                        if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
                            setScenePlay("VIDEO");
                            setVideoSource(allScenes.scene_list[scene_change].scene_video);
                            // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                        }
                        else {
                            setScenePlay("IMAGE");
                            setImageSource(allScenes.scene_list[scene_change].scene_img);
                        }
                    }
    
            }
            else if ( (sceneData.scene_dialogue.length == 0 && sceneData.scenetype == "INTERACTION") || audio_gtv){
                micOn(sceneData,true)
            }
        }
        else{
            //sending data
            // console.log("coming in video end as last and fisrt scene")
            if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){
                axios.patch(`${base_url}/istory/${id}/`,{
                    "story_cv":allScenes.story_cv == null?1:allScenes.story_cv + 1 
                })
                analyticssocket.send(JSON.stringify({"type":"from video end","sd":allScenes.scene_list[allScenes.scene_list.length -1]}))
            }
        }
    };

    const handleLoadedData = () => {
        if (play_btn) {
            videoRef.current.play();
        }
        setvideo_load(true)
    };
    const handleAudioEnded = () => {
        audioRef.current.currentTime = audioRef.current.duration - 1
        
        // if (videoRef.current != null){
        //     videoRef.current.autoplay = false
        //     videoRef.current.load()
        // }
        // Your code to be executed when the video finishes playing
        const dData = sceneData.scene_dialogue[dialogueIndex]

        if (!freeze) {
            const textDuration = parseInt(audioRef.current.duration) * 1000;

            let text_video_duration = 0
            let video_greater_audio
            if (scenePlay === "VIDEO") {
                if (textDuration > (videoRef.current != null ? videoRef.current.duration * 1000 : 0)) {
                    video_greater_audio = false
                    text_video_duration = textDuration
                }
                else {
                    video_greater_audio = true
                    // text_video_duration = (videoRef.current != null ? ((videoRef.current.duration - videoRef.current.currentTime - 2)*1000) : 0) 
                    setAudio_gtv(true)   
                    // text_video_duration = (videoRef.current != null ? (videoRef.current.duration * 1000) : 0)
                }
            }

            if (sceneData.interaction_from_scene.length && dialogueIndex == sceneData.scene_dialogue.length - 1 ) {
                if(initialInteraction && !video_greater_audio) {
                    setTimeout(() => {
                        setInitialInteraction(false)
                        micOn(sceneData, true)
                        // micOnApi(sceneData);
                    }, text_video_duration + (dData.dialog_pause_period * 1000));
                } 
            }
            else if (sceneData.email_interaction_scene.length && dialogueIndex == sceneData.scene_dialogue.length - 1 ) {
                if(!video_greater_audio) {
                    setTimeout(() => {
                        handleEmailOpen()
                        // micOnApi(sceneData);
                    }, text_video_duration + (dData.dialog_pause_period * 1000));
                } 
            }
            else if (dialogueIndex == sceneData.scene_dialogue.length - 1) {
                console.log("last scene of the dialogue  it will come")
                // sending sceneData after last dialogue
                if (!video_greater_audio){
                    if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){

                        analyticssocket.send(JSON.stringify({"type":"coming audio end","sd":sceneData}))
                    }
                }
                // let video_duration = 0
                // if(videoRef.current!= null){
                //     video_duration  = (videoRef.current.duration - videoRef.current.currentTime)*1000
                //     text_video_duration = video_duration>textDuration?video_duration:textDuration
                // }

                setTimeout(() => {
                    if(sceneData.external_redirect_url){
                        // const a = document.createElement('a');
                        // a.href = sceneData.external_redirect_url;
                        // a.target = '_blank';
                        // document.getElementById("root").append(a)
                        // a.click();
                        if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){

                            analyticssocket.send(JSON.stringify({"type":"from play istory when redirection link","sd":sceneData}))
                        }
                        window.location.href = sceneData.external_redirect_url
                        // window.open(sceneData.external_redirect_url, '_blank');
                    }
                    else{

                        let scene_change = currentScene + 1
                        if (sceneData.navigation) {
                            const index = allScenes.scene_list.findIndex(value => value.id == sceneData.navigation);
                            scene_change = index;
                            setPrevious(currentScene)
                        }
                        setPastScene(currentScene)
                        setCurrentScene(scene_change)
                        setInitialRender(true);
                        setSceneData(allScenes.scene_list[scene_change]);
                        setSceneType(allScenes.scene_list[scene_change].scenetype)
                        setPlay_btn(false)
                        if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
                            setScenePlay("VIDEO");
                            setVideoSource(allScenes.scene_list[scene_change].scene_video);
                        }
                        else {
                            setScenePlay("IMAGE");
                            setImageSource(allScenes.scene_list[scene_change].scene_img);
                        }

                    }
                }, text_video_duration + (dData.dialog_pause_period * 1000));
                //need to logics here also
            }
            else {
                if(dData) {
                    setTimeout(() => {
                        playSpeech(sceneData, dialogueIndex + 1)
                    }, dData.dialog_pause_period * 1000)
                }
                else{
                    playSpeech(sceneData, dialogueIndex + 1) 
                }
                

            }
        }

        if(interactionSuccess){
            setInteractionSuccess(!interactionSuccess)
            setInitialInteraction(!initialInteraction)
            setDisplayText("")
            setCurrentScene(interactionSceneChange)
            setInitialRender(true);
            setSceneType(allScenes.scene_list[interactionSceneChange].scenetype)
            setSceneData(allScenes.scene_list[interactionSceneChange]);
            setPlay_btn(false)
            if (allScenes.scene_list[interactionSceneChange].scene_play == "VIDEO") {
                setScenePlay("VIDEO");
                setVideoSource(allScenes.scene_list[interactionSceneChange].scene_video);
            }
            else {
                setScenePlay("IMAGE");
                setImageSource(allScenes.scene_list[interactionSceneChange].scene_img);
            }
        }


    };

    const handleAudioLoadedData = () => {
        if(freeze){
            audioRef.current.pause();
        }
        else{
            audioRef.current.play();
        }    
        if (mute) {
            if (audioRef.current){
                audioRef.current.muted = true
            }    
            setMute(true)       
        }
    };


    function playiStory(value) {
        if (!freeze) {

            if (!play_btn && (nextScene || value)) {
                if (scenePlay == "VIDEO") {
                    videoRef.current.play()
                }
                if (audioRef.current) {
                    audioRef.current.pause()
                }
                setNextScene(true)
                setPlay_btn(true)


                if (sceneData.scene_dialogue.length > 0) {
                    playText(sceneData)
                }
                else {
                    if (caption){
                       var loadcaption=true
                    }
                    setCaption(false)
                    if (sceneData.scenetype == "NONINTERACTION") {
                        let nondialogue_pauses=setTimeout(() => {
                            if (sceneData.external_redirect_url) {
                                // const a = document.createElement('a');
                                // a.href = sceneData.external_redirect_url;
                                // a.target = '_blank';
                                // document.getElementById("root").append(a)
                                // a.click();
                                if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){

                                    analyticssocket.send(JSON.stringify({"type":"from play istory when redirection link","sd":sceneData}))
                                }
                                window.location.href = sceneData.external_redirect_url
                                // window.open(sceneData.external_redirect_url, '_blank');
                            }
                            else{
                                setFreeze (false)
                                if(!play_btn){
                                    if(scenePlay !== "VIDEO"){
                                        setPlay_btn(false)
                                    }
                                    if (loadcaption){
                                        setCaption(true)
                                    }
                                    let scene_change = currentScene + 1
                                    if (sceneData.navigation) {
                                        const index = allScenes.scene_list.findIndex(value => value.id == sceneData.navigation);
                                        scene_change = index;
                                        setPrevious(currentScene)
                                    }
                                    if (sceneData.scene_play == "IMAGE") {
                                        // sceneData sending for image non interaction non dailogue
                                        if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){

                                            analyticssocket.send(JSON.stringify({"type":"image without Dia, NonIn","sd":sceneData}))
                                        }
                                        setPastScene(currentScene)
                                        setCurrentScene(scene_change)
                                        setInitialRender(true);
                                        setSceneType(allScenes.scene_list[scene_change].scenetype)
                                        setSceneData(allScenes.scene_list[scene_change]);
                                        if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
                                            setScenePlay("VIDEO");
                                            setVideoSource(allScenes.scene_list[scene_change].scene_video);
                                            // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                                        }
                                        else {
                                            setScenePlay("IMAGE");
                                            setImageSource(allScenes.scene_list[scene_change].scene_img);
                                        }
                                    }
                                }
                            }
                        }, 3000);
                        setNonDialogue_pause(nondialogue_pauses)
                    }
                    else if(sceneData.scenetype == "INTERACTION") {
                        let interaction = []
                        interaction = sceneData.interaction_from_scene
                        if(scenePlay !== "VIDEO")
                        {
                            micOn(sceneData, true)
                        }
                        // micOnApi(sceneData);
                    }
                    else {
                        handleEmailOpen()
                    }
                }
            } else if (nextScene) {
                setPlay_btn(false)
                if(audioRef.current){
                    audioRef.current.pause()
                }
                
                
                setFreeze(true)
                if(sceneData.scene_dialogue.length <= 0){
                    
                    setNonDialogue(false)
                }
               
                if (scenePlay === "VIDEO") {
                    videoRef.current.pause()
                }
                if (micOnState) {
                    micRecogniser.stopContinuousRecognitionAsync()
                }
            }

        }
        else {
            if (audioRef.current) {
                audioRef.current.play()
            }

            setFreeze(false)
            setPlay_btn(true)
            if (scenePlay === "VIDEO") {
                videoRef.current.play()
            }
            if( sceneType =='INTERACTION'){
                      setInitialInteraction(true)
            }         
           
        }

    }

    const playSpeech = (data, index) => {
        const dData = data.scene_dialogue[index]
        const i = index + 1;

        let interaction = []
        if (data.scenetype == 'INTERACTION') {
            interaction = data.interaction_from_scene
        }



        if (data.scene_dialogue.length > 0) {
            setCaptionText(data.scene_dialogue[index].caption)
            setAudioSource(data.scene_dialogue[index].dialog_text)
            if (audioSource==data.scene_dialogue[index].dialog_text)
            {   
                audioRef.current.load()
            }
        }

        setDialogueIndex(index)
       
        // const textDuration = 10000;

        // let text_video_duration = 0

        // if ( textDuration > (videoRef.current != null? videoRef.current.duration *1000 : 0) ){
        //     text_video_duration = textDuration
        // }
        // else{
        //     text_video_duration = (videoRef.current != null ? (videoRef.current.duration * 1000) : 0)
        // }

        // if (interaction.length && i == data.scene_dialogue.length - 1 && freeze) {
        //     console.log("interaction scene enabling")
        //     setTimeout(() => {
        //         micOnApi(data);
        //     }, (text_video_duration + (dData.dialog_pause_period * 1000) ));
        // }
        // else if (i == data.scene_dialogue.length - 1 && freeze) {
        //     console.log("inside anonymous else if")
        //     setTimeout(() => {
        //         if(data.external_redirect_url){
        //             window.location.href = sceneData.external_redirect_url
        //             // window.open(data.external_redirect_url, '_blank');
        //         }
        //         else{
        //             let scene_change = currentScene + 1
        //             if (data.navigation) {
        //                 scene_change = data.navigation - 1;
        //             }
        //             setCurrentScene(scene_change)
        //             setInitialRender(true);
        //             setSceneData(allScenes.scene_list[scene_change]);
        //             setPlay_btn(false)
        //             setSceneType(allScenes.scene_list[scene_change].scenetype)
        //             if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
        //                 setScenePlay("VIDEO");
        //                 setVideoSource(allScenes.scene_list[scene_change].scene_video);
        //             }
        //             else {
        //                 setScenePlay("IMAGE");
        //                 setImageSource(allScenes.scene_list[scene_change].scene_img);
        //             }
        //         }   
        //     }, text_video_duration + (dData.dialog_pause_period * 1000) );
        //     //need to logics here also
        // }
        // else if (freeze) {
        //     console.log("inside anonymous else block")
        //     setTimeout(() => {
        //         playSpeech(data, i)
        //     }, textDuration + (dData.dialog_pause_period * 1000))
        // }
        // // };  
    };

    const playText = async (data) => {
        const dialogueData = data.scene_dialogue

        if (dialogueData) {
            playSpeech(data, 0)
        }
        else {
            //logics needs to be written later
            setTimeout(() => {
                if(data.external_redirect_url){
                    if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){

                        analyticssocket.send(JSON.stringify({"type":"from play istory when redirection link","sd":sceneData}))
                    }
                    window.location.href = sceneData.external_redirect_url
                    // window.open(data.external_redirect_url, '_blank');
                }
               
               
                else{
                    let scene_change = currentScene + 1
                    if (data.navigation) {
                        const index = allScenes.scene_list.findIndex(value => value.id == sceneData.navigation);
                        scene_change = index;
                        setPrevious(currentScene)
                    }
                    setPastScene(currentScene)
                    setCurrentScene(scene_change)
                    setInitialRender(true);
                    setSceneType(allScenes.scene_list[scene_change].scenetype)
                    setSceneData(allScenes.scene_list[scene_change]);
                    if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
                        setScenePlay("VIDEO");
                        setVideoSource(allScenes.scene_list[scene_change].scene_video);
                    }
                    else {
                        setScenePlay("IMAGE");
                        setImageSource(allScenes.scene_list[scene_change].scene_img);
                    }
                }

            }, 3000);
        }

    }

    function micOnApi(data) {
        const headers = {
            headers: {
                'Ocp-Apim-Subscription-Key': config.speech_key,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        axios.post(`https://${config.speech_region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, null, headers)
            .then(function (response) {
                // setAuthToken(response.data)
                micOn(data, response.data, true)

            })
            .catch(function (error) {
                console.log("error",error)
            });
    }

    function micOn(data, micBoolean) {
        setMicOnState(true)
        const speechConfig = SpeechConfig.fromAuthorizationToken(authToken, "eastus");
        speechConfig.speechRecognitionLanguage = 'en-US';

        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
        setCaption(false)
        if(caption){
            var captionMute=true
        }
        
        // setDisplayText("speak into your microphone...")
        recognizer.startContinuousRecognitionAsync();
        setMicRecogniser(recognizer)
        recognizer.recognizing = (s, e) => {
            // console.log(`RECOGNIZING: Text=${e.result.text}`);
        };
         myInterval = setTimeout(() => {
            clearTimeout(myInterval)
            setMicOnState(null)
            recognizer.stopContinuousRecognitionAsync();
            handleOpen()
        }, 1000 * 20);
        recognizer.recognized = (s, e) => {
            console.log("recognized: ", e.result.text)
            clearTimeout(myInterval)
            let transcript = ""
            if (id == 142){
                transcript = ""
            }
            else{
                transcript = e.result.text
            }
            if (e.result.reason == ResultReason.RecognizedSpeech) {             
                setDisplayText(transcript)

                const responses = data.interaction_from_scene

                let responseSuccess;
                let responseIndex;
                let scene_change;

                let gen_res = data.general_response;
                let arr_keywords = []
                let user_response_name = ''
                let user_response_keyword = ''
                responses.map((data, i) => {
                    if (!responseSuccess) {
                        JSON.parse(data.user_response_tags.replace(/'/g,'"')).map((keyword,key_i) => {

                            let combination=keyword.replace(/[,.?]/g,'').toLowerCase().split(" ")
                            let user_word = e.result.text.replace(/[,.?]/g,'').toLowerCase().split(" ")
                            let count=0
                            combination.map(keyword=>{
                                if(user_word.includes(keyword)){
                                    user_response_name = data.user_response_name
                                    user_response_keyword = keyword
                                    // console.log(user_word,"keyword---",keyword)
                                    count+=1;
                                }
                            })
                            arr_keywords.push({mLength: count, mIndex: i,cLength:combination.length})

                        })
                    }

                })
                let validIndex = []
                let highest=Math.max(...arr_keywords.map(object=>object.mLength))
                if(gen_res && highest>0){
                    if(highest==1){
                        validIndex=arr_keywords.filter(object=>(object.mLength===highest && object.cLength===highest))
                        if(validIndex.length<=0){
                            validIndex=arr_keywords.filter(object=>(object.mLength===highest))
                        }
                    }
                    else{
                        validIndex=arr_keywords.filter(object=>object.mLength===highest)
                        if(validIndex.length<=0){
                            validIndex=arr_keywords.filter(object=>(object.mLength===highest))
                        }
                    }
                }
                else if(!gen_res && highest > 0){
                    validIndex=arr_keywords.filter(object=>object.mLength===highest && object.cLength===highest) 
                }
                if (validIndex.length){
                    responseIndex = validIndex[0].mIndex
                    recognizer.stopContinuousRecognitionAsync();
                    responseSuccess = true;
                }

                if (responseSuccess) {
                    if(!isTabSwitched && allScenes.status == "PUBLISHED" && from_params === null){
                        
                        analyticssocket.send(JSON.stringify({"type":"from interaction success","sd":sceneData,"response":{"text":user_response_keyword,"name":user_response_name}}))
                    }
                    const index = allScenes.scene_list.findIndex(value => value.id == responses[responseIndex].to_scene);

                    scene_change = index
                    // scene_change = responses[responseIndex].to_scene - 1

                    if (responses[responseIndex].dialog_success_text) {

                        recognizer.stopContinuousRecognitionAsync();
                        setAudioSource(responses[responseIndex].dialog_success_text)
                        setInteractionSuccess(true)
                        setInteractionSceneChange(scene_change)
                        setPastScene(currentScene)
                        setPrevious(currentScene)
                        setInitialInteraction(!initialInteraction)
                        setMicOnState(null)
                        if(captionMute){
                            setCaption(true)
                        }                       
                        // let successduration = parseInt(audioRef.current.duration) * 1000
                        // setTimeout(() => {
                        //     setCurrentScene(scene_change)
                        //     setInitialRender(true);
                        //     setSceneType(allScenes.scene_list[scene_change].scenetype)
                        //     setSceneData(allScenes.scene_list[scene_change]);
                        //     setPlay_btn(false)
                        //     if (allScenes.scene_list[scene_change].scene_play == "VIDEO") {
                        //         setScenePlay("VIDEO");
                        //         setVideoSource(allScenes.scene_list[scene_change].scene_video);
                        //     }
                        //     else {
                        //         setScenePlay("IMAGE");
                        //         setImageSource(allScenes.scene_list[scene_change].scene_img);
                        //     }

                        // }, parseInt(successduration));

                    }
                }
                else {
                    clearTimeout(myInterval)
                    if (e.result.text!== undefined && e.result.text!==""){
                        setAudioSource(responses[0].dialog_failure_text)
                    }                   
                    if( audioRef.current.currentTime>0 && e.result.text!== undefined && e.result.text!==""){
                        audioRef.current.currentTime=0
                        audioRef.current.play()
                    }   
                }

            }
            else if (e.result.reason == ResultReason.NoMatch) {
                setDisplayText(false)
            }
        };

        recognizer.canceled = (s, e) => {

            if (e.reason == CancellationReason.Error) {
            }

            recognizer.stopContinuousRecognitionAsync();
        };

        recognizer.sessionStopped = (s, e) => {
            recognizer.stopContinuousRecognitionAsync();
        }
        if (micBoolean) {
            recognizer.stopContinuousRecognitionAsync();
        }
    }

    const next_scene = () => {
        clearTimeout(nondialogue_pause)
        if (!play_btn) {
            setPastScene(currentScene)
            setNextScene(false)
            setPlay_btn(false)
            setFreeze(false)
            setCurrentScene(currentScene + 1)
            setInitialRender(true);
            setSceneType(allScenes.scene_list[currentScene + 1].scenetype)
            setSceneData(allScenes.scene_list[currentScene + 1]);
            if (allScenes.scene_list[currentScene + 1].scene_play == "VIDEO") {
                setScenePlay("VIDEO");
                setVideoSource(allScenes.scene_list[currentScene + 1].scene_video);

                // setAudioSource(allScenes.scene_list[scene_change].scene_video)
            }
            else {
                setScenePlay("IMAGE");
                setImageSource(allScenes.scene_list[currentScene + 1].scene_img);
            }
        }
        else {
            if (sceneData.scenetype == "NONINTERACTION") {
                setNextScene(true)
                setPlay_btn(false)
                setPastScene(currentScene)
                setCurrentScene(currentScene + 1)
                setInitialRender(true);
                setSceneType(allScenes.scene_list[currentScene + 1].scenetype)
                setSceneData(allScenes.scene_list[currentScene + 1]);
                if (allScenes.scene_list[currentScene + 1].scene_play == "VIDEO") {
                    setScenePlay("VIDEO");
                    setVideoSource(allScenes.scene_list[currentScene + 1].scene_video);

                    // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                }
                else {
                    setScenePlay("IMAGE");
                    setImageSource(allScenes.scene_list[currentScene + 1].scene_img);
                }
            }
        }

    }
    const previous_scene = () => {
        clearTimeout(nondialogue_pause)
        if (!play_btn) {
            
            if(previous !==null && pastScene===previous){ 
                setNextScene(false)
                setFreeze(false)
                setPlay_btn(false)
                setCurrentScene(previous)
                setInitialRender(true);
                setSceneType(allScenes.scene_list[previous].scenetype)
                setSceneData(allScenes.scene_list[previous]);
                if (allScenes.scene_list[previous].scene_play == "VIDEO") {
                    setScenePlay("VIDEO");
                    setVideoSource(allScenes.scene_list[previous].scene_video);
    
                    // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                }
                else {
                    setScenePlay("IMAGE");
                    setImageSource(allScenes.scene_list[previous].scene_img);
                }

            }
            else{
                setNextScene(false)
            setFreeze(false)
            setPlay_btn(false)
            setCurrentScene(currentScene - 1)
            setInitialRender(true);
            setSceneType(allScenes.scene_list[currentScene - 1].scenetype)
            setSceneData(allScenes.scene_list[currentScene - 1]);
            if (allScenes.scene_list[currentScene - 1].scene_play == "VIDEO") {
                setScenePlay("VIDEO");
                setVideoSource(allScenes.scene_list[currentScene - 1].scene_video);

                // setAudioSource(allScenes.scene_list[scene_change].scene_video)
            }
            else {
                setScenePlay("IMAGE");
                setImageSource(allScenes.scene_list[currentScene - 1].scene_img);
            }

            }
            
        }
        else {
            if(previous !==null && pastScene===previous){               
                if (sceneData.scenetype == "NONINTERACTION") {
                    setPlay_btn(false)
                    setNextScene(true)
                    setCurrentScene(previous)
                    setInitialRender(true);
                    setSceneType(allScenes.scene_list[previous].scenetype)
                    setSceneData(allScenes.scene_list[previous]);
                    if (allScenes.scene_list[previous].scene_play == "VIDEO") {
                        setScenePlay("VIDEO");
                        setVideoSource(allScenes.scene_list[previous].scene_video);
    
                        // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                    }
                    else {
                        setScenePlay("IMAGE");
                        setImageSource(allScenes.scene_list[previous].scene_img);
                    }
                }
            
            }
            else{
                if (sceneData.scenetype == "NONINTERACTION") {
                    setPlay_btn(false)
                    setNextScene(true)
                    setCurrentScene(currentScene - 1)
                    setInitialRender(true);
                    setSceneType(allScenes.scene_list[currentScene - 1].scenetype)
                    setSceneData(allScenes.scene_list[currentScene - 1]);
                    if (allScenes.scene_list[currentScene - 1].scene_play == "VIDEO") {
                        setScenePlay("VIDEO");
                        setVideoSource(allScenes.scene_list[currentScene - 1].scene_video);
    
                        // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                    }
                    else {
                        setScenePlay("IMAGE");
                        setImageSource(allScenes.scene_list[currentScene - 1].scene_img);
                    }
                }
            }
            
        }


    }
    const mic_On=()=>{
        if (micOnState){
            if(micRecogniser){
                micRecogniser.stopContinuousRecognitionAsync()
                setMicOnState(false)
                setInitialInteraction(true)
            }
           
        }
        else{
            if(micOnState==false){
                micOn(sceneData, true)
                setInitialInteraction(false)
            }

        }

        
    }
    const replay=()=>{
        if (!play_btn) {
            setNextScene(false)
            setFreeze(false) 
            setPlay_btn(false)
            setCurrentScene(0)
            setInitialRender(true);
            setSceneType(allScenes.scene_list[0].scenetype)
            setSceneData(allScenes.scene_list[0]);
            if (allScenes.scene_list[0].scene_play == "VIDEO") {
                setScenePlay("VIDEO");
                setVideoSource(allScenes.scene_list[0].scene_video);

                // setAudioSource(allScenes.scene_list[scene_change].scene_video)
            }
            else {
                setScenePlay("IMAGE");
                setImageSource(allScenes.scene_list[0].scene_img);
            }
            setIsIstoryEnded(false)
        }
        else {
            if (sceneData.scenetype == "NONINTERACTION") {
                setPlay_btn(false)
                setNextScene(true)
                setCurrentScene(0)
                setInitialRender(true);
                setSceneType(allScenes.scene_list[0].scenetype)
                setSceneData(allScenes.scene_list[0]);
                if (allScenes.scene_list[0].scene_play == "VIDEO") {
                    setScenePlay("VIDEO");
                    setVideoSource(allScenes.scene_list[0].scene_video);

                    // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                }
                else {
                    setScenePlay("IMAGE");
                    setImageSource(allScenes.scene_list[0].scene_img);
                }
            }
            else {
                setPlay_btn(false)
                setNextScene(true)
                setCurrentScene(0)
                setInitialRender(true);
                setSceneType(allScenes.scene_list[0].scenetype)
                setSceneData(allScenes.scene_list[0]);
                if (allScenes.scene_list[0].scene_play == "VIDEO") {
                    setScenePlay("VIDEO");
                    setVideoSource(allScenes.scene_list[0].scene_video);

                    // setAudioSource(allScenes.scene_list[scene_change].scene_video)
                }
                else {
                    setScenePlay("IMAGE");
                    setImageSource(allScenes.scene_list[0].scene_img);
                }
            }
        }

    }
    const mute_audio=()=>{

        if (!mute) {
            if (audioRef.current){
                audioRef.current.muted = true
            }
            if(videoRef.current){
                videoRef.current.muted = true
            }
            setMute(true)
        }
        else{
            if (audioRef.current){
                audioRef.current.muted = false
            }
            if(videoRef.current){
                videoRef.current.muted = false
            }
            setMute(false)
        }


       
    }
    const reload = () => {
        handleClose()
        replay()
        clearTimeout(myInterval)
    }
    const replay_scene = () => {
        handleClose()
        clearTimeout(myInterval)
        setInitialInteraction(true)
        setNextScene(true)
        setUpdateState(!updateState)
        setFreeze(false)
        setPlay_btn(false)
        setCurrentScene(currentScene)
        setInitialRender(true);
        setSceneType(allScenes.scene_list[currentScene].scenetype)
        setSceneData(allScenes.scene_list[currentScene]);
        if (allScenes.scene_list[currentScene].scene_play == "VIDEO") {
            setScenePlay("VIDEO");
            setVideoSource(allScenes.scene_list[currentScene].scene_video);
            if(videoSource===allScenes.scene_list[currentScene].scene_video){
                videoRef.current.load()
            }
        }
        else {
            setScenePlay("IMAGE");
            setImageSource(allScenes.scene_list[currentScene].scene_img);
        }
        
    }
    const captionLoad=()=>{
        if(caption){
            setCaption(false)
        }
        else{
            setCaption(true)
        }
    }
    const mouseEnter=()=>{
        setIsDisabled(false)
        let Mousemove
        window.addEventListener("mousemove",()=>{
             Mousemove=true
        })
        if(!Mousemove){
            setIsDisabled(false)
            setTimeout(()=>{
                Mousemove=false
            },5000)
        }
        else{
            setIsDisabled(true)
        }
    
       
    }
    const doubleClick=(e)=>{
    
    if (e.detail==2){
        setIsDisabled(!isDisabled)
    }
    }
    
    return (
        <div>
            <div>
                <div onMouseLeave={()=>{setIsDisabled(true)}} onMouseEnter={mouseEnter} onClick={(e)=>doubleClick(e)} style={{ position: "relative" }} >
                    {storyStatus === "PUBLISHED" ? null : <Box component="img" src={watermark} sx={{ position: "absolute", width: "100%", height: "100vh", objectFit: "cover",mixBlendMode:"normal" }} />}
                    <Box className="full-screen-image">
                        {scenePlay == 'VIDEO' ?
                            <video
                                ref={videoRef}
                                onLoadedData={handleLoadedData}
                                onEnded={handleEnded}
                                autoPlay 
                                playsInline
                                src={videoSource} />
                            // <video ref={videoRef}
                            //     onLoadedData={handleLoadedData}
                            //     onEnded={handleEnded}>
                            //     <source src={demo_video} type="video/mp4"></source>
                            // </video>
                            :
                            <img ref={image_ref} src={imageSource} onLoad={()=>setImg_load(true)} />
                        }
                        {audioSource ? <audio ref={audioRef}
                            onLoadedData={handleAudioLoadedData}
                            onEnded={handleAudioEnded}
                            autoPlay 
                            src={audioSource} /> : null}
                        
                    </Box>
                    <Box style={{ display: isDisabled ? "none" : "block" }} sx={{ position: "absolute", top: slides_position ? slides_position.top > 0 ? slides_position.top:"0px" : "0px", width: "100%", background: "rgba(33, 37, 41, 0.9)" }}>
                            <p style={{ color: "white", textAlign: "center" }}>{storyname}</p>
                    </Box>
                    <Box  sx={{ flexDirection: "row", position: "absolute", bottom: slides_position ? slides_position.top > 0 ? slides_position.top:"0px" : "0px", width: "100%", background: "rgba(33, 37, 41, 0.9)"}}>
                    {caption && captionText && (isDisabled || ! isDisabled) ?<div className="caption" style={isDisabled?{position: "absolute",bottom:"40px",display:"flex",alignItems:"center",justifyContent:"center"}:{position: "absolute",bottom:"60px",display:"flex",alignItems:"center",justifyContent:"center"}}><Typography sx={{fontSize:"16px",background: "rgba(21, 21, 21, 0.5)",textAlign:"center",width:"40%",fontWeight:"500"}}>{captionText}</Typography></div> : null}
                        <Box style={{ display: isDisabled ? "none" : "flex" }}  sx={{ textAlign: "center",display:"flex",alignItems:"center",justifyContent:"center",gap:"8px",padding:"8px" }}>
                            {sceneType =="NONINTERACTION" || sceneType =="EMAILINTERACTION" || (!play_btn )||micOnState==null  ?  <Box  component={ MicOffIcon}  sx={{ padding: "8px",color:"#fff",bgcolor:"#57595a",borderRadius:"50%",fontSize:{xs:"16px",sm:"24px"} }}  ></Box>: <Box component={micOnState && sceneType=="INTERACTION" ? MicIcon: MicOffIcon}sx={{ padding: "8px",color:"#fff",bgcolor:"#b00a4d",borderRadius:"50%",cursor:"pointer",fontSize:{xs:"16px",sm:"24px"} }} onClick={mic_On} ></Box>}


                            {/* <Box component="img" src={Mic} /> */}
                            <Box component={play_btn ? PauseIcon:PlayArrowIcon} sx={play_btn ?{ padding: "8px", cursor: "pointer",color:"#fff",fontSize:{xs:"16px",sm:"24px"},bgcolor:"#204486",borderRadius:"50%" }:{ padding: "8px", cursor: "pointer",color:"#fff",fontSize:{xs:"16px",sm:"24px"},bgcolor:"#004ea6",borderRadius:"50%" }}  onClick={() => playiStory(true)} />
                            <Box component={mute ?VolumeOffIcon:VolumeUpIcon} sx={{ padding: "8px",color:"#fff",cursor:"pointer",fontSize:{xs:"16px",sm:"24px"} }}  onClick={mute_audio}/>
                            <Box component={SkipPreviousIcon} sx={sceneType=="INTERACTION" && play_btn ? { padding: "8px",cursor:"not-allowed",fontSize:{xs:"16px",sm:"24px"},color:"#fff", }:{ padding: "8px",cursor:"pointer",fontSize:{xs:"16px",sm:"24px"},color:"#fff",}}  onClick={previous_scene} />
                            <Box component={SkipNextIcon} sx={sceneType=="INTERACTION" && play_btn ? { padding: "8px",cursor:"not-allowed",fontSize:{xs:"16px",sm:"24px"},color:"#fff", }:{ padding: "8px",cursor:"pointer",fontSize:{xs:"16px",sm:"24px"},color:"#fff",}}  onClick={next_scene} />
                            <Box component={ caption ? ClosedCaptionIcon :ClosedCaptionDisabledIcon} sx={{ padding: "8px",cursor:"pointer",color:"white",fontSize:{xs:"16px",sm:"24px"}, }} onClick={captionLoad} src={Caption} />
                            <Box component={ReplayIcon} sx={{ padding: "8px",cursor:"pointer",fontSize:{xs:"16px",sm:"24px"},color:"#fff", }}  onClick={replay}/>
                            {/* <Box component="img" sx={{ padding: "8px",cursor:"pointer" }} src={FullScreen} /> */}
                        </Box>
                    </Box>
                </div>
                {displayText ? <div className="displayText" style={{position:"absolute"}}><h2>{displayText}</h2></div> : null}
            </div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{color:"#AAAAAA",textAlign:"center"}}>
                    Session paused. Please click resume to continue
                    </Typography>
                    <Button onClick={replay_scene} sx={{ textTransform: "none", background: "#004ea6", color: "#fff","&:hover": { background: "#1A68BF" },  margin: "20px 0px 10px 0px",padding:"5px 20px 5px 20px"}}>Resume</Button>
                    {/* <Button onClick={reload}  sx={{ textTransform: "none",border:"1px solid #AAAAAA",padding:"5px 20px 5px 20px",color: "#AAAAAA", "&:hover": { background: "#AAAAAA",color:"#fff" }, float: "right", margin: "25px 25px 0px 0px" }}>Cancel</Button> */}
                </Box>
            </Modal>
            {/* <Button onClick={handleEmailOpen}>Open modal</Button> */}
            <Modal
            open={emailOpen}
            // onClose={handleEmailClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                {/* <Box>
                    <Typography id="modal-modal-title" sx={{color:"#FFF", textAlign:"initial"}} variant="h6" component="h2">
                        Enter Your Email
                    </Typography>
                </Box> */}
                <Box sx={{width:"inherit"}}>
                    <form onSubmit={handleEmailSubmit}>
                        <Box sx={{width:"100%"}}>
                            <input
                                type="email"
                                placeholder="Please type in your email"
                                className="darkEmailInputStyle"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Box>
                        
                        <Box sx={{ textAlign:"center"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                style={{ marginTop: '10px', marginRight: '10px' }}
                                onClick={handleEmailSkip}
                            >
                                Skip
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{ marginTop: '10px' }}
                            >
                                Submit
                            </Button>

                        </Box>
                    </form>
                </Box>
            </Box>
            </Modal>
        </div>
    )
}
export default Istory;